



















































































































import { Component, Mixins } from 'vue-property-decorator';

import { } from 'client-website-ts-library/filters';
import { Office, Profile, WebsiteLevel, WebsiteProfileDisplayType } from 'client-website-ts-library/types';

import { API, Config } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';
import Offices from '@/components/Offices.vue';
import Profiles from '@/components/Profiles.vue';
import ImageBox from '@/components/ImageBox.vue';
import Awards from '@/components/Awards.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { ProfileFilter } from 'client-website-ts-library/filters/ProfileFilter';
import GridNavigation from '@/components/GridNavigation.vue';
import GridNavigationItem from '@/components/GridNavigationItem.vue';

@Component({
  components: {
    Offices,
    ImageBox,
    Awards,
    Profiles,
    VideoPlayer,
    GridNavigation,
    GridNavigationItem,
  },
})
export default class Home extends Mixins(View) {
  private offices: Office[] = [];

  private profiles: Profile[] = [];

  private readonly inRealEstate!: string;

  get month(): number {
    return (new Date().getMonth() + 1);
  }

  shuffle(array: Office[]): Office[] {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  mounted() {
    API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
      this.offices = this.shuffle(offices).filter((o) => o.Id !== '3a19ae4e-e72b-42d5-b332-e42737a2b20c');
    });

    API.Profiles.Search(new ProfileFilter({
      SearchLevel: WebsiteLevel.Franchise,
      SearchGuid: Config.Website.Settings!.WebsiteId,
    }), true).then((profiles) => {
      this.profiles = profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Principal);
    });
  }
}
