



































import { DropdownFieldOption } from 'client-website-ts-library/types/Forms/FieldTypes';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

type DropdownListSelection = null | string[] | string | DropdownFieldOption | undefined | (DropdownFieldOption | undefined)[];

@Component
export default class DropDownList extends Vue {
  @Prop()
  private readonly value!: string[];

  @Prop()
  private readonly placeholderText!: string;

  @Prop()
  private readonly options!: DropdownFieldOption[];

  @Prop()
  private readonly searchable!: boolean;

  @Prop()
  private readonly clickable!: boolean;

  @Prop({ default: true })
  private readonly changeable!: boolean;

  @Prop()
  private readonly searchPlaceholder!: string;

  @Prop()
  private readonly noResultsText!: string;

  @Prop()
  private readonly abyssHide!: boolean;

  @Prop()
  private readonly multiple!: boolean;

  @Prop({ default: '' })
  private readonly justifyContent!: string;

  private selection: DropdownFieldOption[] = [];

  private results: DropdownFieldOption[] = [];

  private term = '';

  private expanded = false;

  get hasSelection() {
    return this.selection.length > 0 && this.selection[0] != null;
  }

  get selectionLabel() {
    if (this.multiple && this.selection.length > 1) {
      if (this.selection.length === 2) return `${this.selection[0]!.Label} & ${this.selection[1].Label}`;

      return this.selection.map((item) => item.Label).join(', ');
    }

    return this.selection.length === 0 ? null : this.selection[0].Label;
  }

  @Watch('options')
  @Watch('term')
  updateResults() {
    const loweredTerm = this.term.trim().toLowerCase();

    if (!this.searchable || loweredTerm.length === 0) {
      this.results = this.options;
      return;
    }

    this.results = this.options.filter((opt) => opt.Label.toLowerCase().indexOf(loweredTerm) !== -1);
  }

  @Watch('value')
  updateValue(old: string[], newVal: string[]) {
    console.debug('Setting value', this.value, old, newVal);

    if (old && newVal && old !== newVal) {
      this.handleValueUpdated();
    }
  }

  toggleOpen() {
    this.expanded = !this.expanded;
  }

  select(selection: DropdownListSelection, silent: boolean) {
    if (selection == null || (selection instanceof Array && selection.length === 0)) {
      this.selection = [];
    } else if (this.multiple) {
      if (selection instanceof Array) {
        this.selection = selection as DropdownFieldOption[];
      } else {
        this.selection.push(selection as DropdownFieldOption);
      }
    } else {
      if (selection instanceof Array) {
        this.selection = [selection[0] as DropdownFieldOption];
      } else {
        this.selection = [selection as DropdownFieldOption];
      }

      this.expanded = false;
    }

    if (!silent) this.$emit('input', this.multiple ? this.selection.map((itm) => itm.Value) : this.selection[0].Value);
  }

  handleValueUpdated() {
    const raw = this.value;

    console.debug('Setting value', raw);

    let val: DropdownListSelection = null;

    if (raw == null) {
      val = null;
    } else if (this.multiple) {
      val = raw instanceof Array ? raw : [raw];

      val = val.map((v) => this.options.find((o) => o.Value === v)).filter((v) => v !== null);
    } else {
      val = raw instanceof Array ? raw[0] : raw;

      val = this.options.find((o) => o.Value === val);
    }

    this.select(val, true);
  }

  mounted() {
    console.debug('Setting value', this.value);

    if (this.value) {
      this.handleValueUpdated();
    }

    this.updateResults();
  }
}
