
































// Base CSS
import './assets/css/reset.css';
import './assets/css/fonts.css';
import './assets/css/layout.css';
import './config.css';

import { Component, Vue, Prop } from 'vue-property-decorator';

import { Config } from 'client-website-ts-library/services';
import { ClientWebsiteStatus } from 'client-website-ts-library/types/WebsiteConfig';

import Header from './components/Common/Header.vue';
import Footer from './components/Common/Footer.vue';
import NotificationStack from './components/Notifications/NotificationStack.vue';
import EventPopup from './components/EventPopup.vue';

@Component({
  components: {
    Header,
    Footer,
    NotificationStack,
    EventPopup,
  },
})
export default class App extends Vue {
  @Prop()
  private readonly errored!: boolean;

  get websiteName(): string {
    return Config.Website.Name;
  }

  get isDisabled(): boolean {
    return Config.Website.Settings!.Status === ClientWebsiteStatus.Disabled;
  }
}
