


































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EventPopup extends Vue {
  private active = true;
}
